import Vue from 'vue'
import { ToastPlugin, ModalPlugin, BootstrapVue, IconsPlugin,
  CardPlugin, LayoutPlugin, VBScrollspyPlugin, DropdownPlugin, TablePlugin} from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/style.css'
import '@/assets/css/fontawesome-6.5.2.css'
import pinia from './store/pinia/pinia.js'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(LayoutPlugin)
Vue.use(CardPlugin)
Vue.use(VBScrollspyPlugin)
Vue.use(DropdownPlugin)
Vue.use(TablePlugin)

// Composition API
Vue.use(VueCompositionAPI)

//vue-dialog-loading
import Dialog from 'vue-dialog-loading'
Vue.use(Dialog, {
  dialogBtnColor: '#0f0',
  background:'rgba(0, 0, 0, 0.5)'
})


// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

// Import the component
import VueSkeletonLoader from 'skeleton-loader-vue';
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* import specific icons */
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
/* add icons to the library */
library.add(fas, fab)
/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)
// Register the component globally
Vue.component('vue-skeleton-loader', VueSkeletonLoader)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  pinia,
  render: h => h(App)
}).$mount('#app')
