export default [
    {
        path: '/proxy/plan-setting',
        name: 'proxy-plan-setting',
        component: () => import('@/views/pages/proxy/ProxyPlan.vue')
    },
    {
        path: '/proxy/storage',
        name: 'proxy-storage',
        component: () => import('@/views/pages/proxy/Storage.vue')
    },
    {
        path: '/proxy',
        name: 'proxy-service',
        component: () => import('@/views/pages/proxy/ProxyService.vue')
    },
    {
        path: '/proxy/detail/:id',
        name: 'proxy-detail',
        component: () => import('@/views/pages/proxy/ProxyDetail.vue')
    }
]
