export default [
    {
        path: '/',
        name: 'dashboard',
        component: () => import('@/views/pages/dashboard/Dashboard')
    },
    {
        path: '/cloud-vps',
        name: 'cloud-vps',
        component: () => import('@/views/pages/cloud-vps/CloudVPS')
    },
    {
        path: '/cloud-vps/storage',
        name: 'cloud-vps-storage',
        component: () => import('@/views/pages/cloud-vps/Storage')
    },
    {
        path: '/plan-setting',
        name: 'plan-setting',
        component: () => import('@/views/pages/cloud-vps/PlanSetting.vue')
    },
    {
        path: '/user',
        name: 'user',
        component: () => import('@/views/pages/user/UserSetting')
    },
    {
        path: '/setting',
        name: 'setting',
        component: () => import('@/views/pages/setting/Setting')
    },
    {
        path: '/rgc',
        name: 'rgc',
        component: () => import('@/views/pages/rgc/RGC')
    },
    {
        path: '/addon',
        name: 'addon',
        component: () => import('@/views/pages/addon/Addon')
    }
]
