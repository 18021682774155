export default {

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',

  // Endpoints
  loginEndpoint: '/admin/login',
  checkValidToken: '/token/is-valid',
  proxyActivePlansEndpoint: '/proxy/plans',

  // User Endpoint
  userEndpoint: '/client/profile-info',

  // User Endpoint for admin
  userAdminEndpoint: '/admin/user',

  // plan endpoint
  planListEndpoint: '/admin/plan',
  // plan type endpoint
  planTypeEndpoint: '/admin/plan-type',

  // list all cloud vps
  cloudVpsEndpoint: '/admin/cloud-vps',

  // addon endpoint
  addonEndpoint: '/admin/addon',

  // invoice endpoint
  invoiceEndpoint: '/admin/invoice',

  // proxy endpoint
  proxyEndpoint: '/admin/proxy',

  // setting endpoint
    settingEndpoint: '/admin/setting'
}
