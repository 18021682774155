import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)
function viLoadLocaleMessages () {
  const locales = require.context('./locales/vi', true, /[A-Za-z0-9-_,\s]+\.json$/i)


  const messages = {
    'vi': {},
    'en': {}
  }
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages['vi'][locale] = locales(key)
    }
  })
  return messages
}

function enLoadLocaleMessages () {
  const locales = require.context('./locales/en', true, /[A-Za-z0-9-_,\s]+\.json$/i)

  const messages = {
    'vi': {},
    'en': {}
  }
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages['en'][locale] = locales(key)
    }
  })
  return messages
}

function loadLocalMessages () {
  const messages = {
    'vi': {},
    'en': {}
  }
  messages['vi'] = viLoadLocaleMessages()['vi']
  messages['en'] = enLoadLocaleMessages()['en']
  return messages
}

export default new VueI18n({
  locale: 'vi',
  fallbackLocale: 'en',
  messages: loadLocalMessages()
})
