export default [
    {
        path: '/invoices',
        name: 'invoices',
        component: () => import('@/views/pages/invoice/InvoiceList.vue')
    },
    {
        path: '/invoice/:type',
        name: 'invoice-preview',
        component: () => import('@/views/pages/invoice/InvoiceDetail.vue')
    },
    {
        path: '/invoice/detail/:invoiceId',
        name: 'invoice-detail',
        component: () => import('@/views/pages/invoice/InvoiceDetail.vue')
    }
]
